import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSocketIO from 'vue-3-socket.io'
import SocketIO from 'socket.io-client'

const options = {
	path: `${process.env.VUE_APP_PATH ? process.env.VUE_APP_PATH : ''}/socket.io`,
	autoConnect: true,
	transports: ['websocket','polling'],
	reconnection: true,
	reconnectionAttempts: 9,
	reconnectionDelay: 6000
};

console.log('main js')
console.log('WebSocket URL:', process.env.VUE_APP_API)
console.log('options:',options)
console.log('---')

const socketConnection = SocketIO(process.env.VUE_APP_API, options);

console.log('sc')
console.log(socketConnection)
console.log('sc')

socketConnection.on('connect', () => {
	console.log('WebSocket connected!');
})

socketConnection.on('connect_error', (error) => {
	console.error('WebSocket connection error:', error);
})

socketConnection.on('disconnect', (reason) => {
	console.log('WebSocket disconnected:', reason);
})

createApp(App).use(store).use(router).use(new VueSocketIO({
	debug: true,
	connection: socketConnection,
	vuex: {
		store,
		actionPrefix: "SOCKET_",
		mutationPrefix: "SOCKET_"
	}
})).mount('#app')
